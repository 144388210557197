<script>
import { getModalidadePlataforma, TIPO_PLATAFORMA } from '@/helpers/tipoPlataformaHelper';
import financingService from '@/services/Financing/financingService';
import ordersService from '@/services/Orders/ordersService';
import moment from 'moment';

import XLSX from 'xlsx';

import { DoubleBounce } from 'vue-loading-spinner';

export default {
  components: {
    DoubleBounce,
  },
  data() {
    return {
      loading: true,
      financings: [],
      financings_all: [],
      totalizador: {
        total_investido: 0,
        total_acumulado: 0
      },
      totalizador_all: {
        total_investido: 0,
        total_acumulado: 0
      },
      modalidade: process.env.VUE_APP_TYPE,
      tipoPlataformaType: TIPO_PLATAFORMA,

      // Paginação
      page: 1,
      perPage: 10,
      pages: [],

      // Filtros
      searchBar: '',
      filterOnlyPaid: false,

      // Ordenação
      sort: 'asc',
      sortPrimeiroVencimentoIcon: 'icon-minimal-down',
      sortDataEncerramentoIcon: '',

      // Excel
      items_exportar: [],
    };
  },
  async mounted() {
    
    try {
      await this.getFinancings();
    } catch (e) {
      console.log(e);
      this.loading = false;
    }
  },
  computed: {
    tipoPlataforma() {
    return getModalidadePlataforma();
  },
    displayedData() {
      return this.paginate(this.filteredData);
    },
    filteredData() {
      return this.financings.filter(obj => {
        return(
             (obj.name ? obj.name : '').toLowerCase().includes(this.searchBar.toLowerCase())
        )
      });
    },
  },
  methods: {
    setPages() {
      if (this.pages) {
        this.pages = [];
      }

      const numberOfPages = Math.ceil(this.financings.length / this.perPage);

      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      const page = this.page;
      const perPage = this.perPage;

      const from = (page * perPage) - perPage;
      const to = (page * perPage);

      return data.slice(from, to);
    },
    
    viewRelatorioAnalitico(financingId) {
      console.log("Financing ID ",financingId);
      this.$router.push('/reports/vencimento-ccbs/' + financingId + '/true');
    },
    remuneracaoAcumuladaGeral(valor, financing) {
      if ( financing.fields['Encerramento da Oferta'] && financing.fields['taxa_juros'] && financing.fields['prazo_oferta'] ) {
        let taxaJurosOferta = Number(financing.fields['taxa_juros'].value);
        let prazo = Number(financing.fields['prazo_oferta'].value);
        const paraCalcularPotencia = (1 + (taxaJurosOferta / 100));
        const potenciaCalculada = Math.pow(paraCalcularPotencia, prazo);
        var remuneracaoAcumuladaCalculo = valor * potenciaCalculada;
        return remuneracaoAcumuladaCalculo;
      }
      else {
        return 0;
      }
    },

    filterOnlyPaidCheck() {
      this.$refs.filter_only_paid.click();
    },

    orderByClicked(by) {
      if ( by == 'data_encerramento' ) {
        if ( this.sortDataEncerramentoIcon == 'icon-minimal-down' ) {
          this.sort = 'desc';
          this.sortPrimeiroVencimentoIcon = '';
          this.sortDataEncerramentoIcon = 'icon-minimal-up';
        }
        else {
          this.sort = 'asc';
          this.sortPrimeiroVencimentoIcon = '';
          this.sortDataEncerramentoIcon = 'icon-minimal-down';
        }
        this.financings = this.financings.sort(this.sortByDataEncerramento);
        this.financings_all = this.financings.sort(this.sortByDataEncerramento);
      }
      else {
        if ( this.sortPrimeiroVencimentoIcon == 'icon-minimal-down' ) {
          this.sort = 'desc';
          this.sortDataEncerramentoIcon = '';
          this.sortPrimeiroVencimentoIcon = 'icon-minimal-up';
        }
        else {
          this.sort = 'asc';
          this.sortDataEncerramentoIcon = '';
          this.sortPrimeiroVencimentoIcon = 'icon-minimal-down';
        }

        this.financings = this.financings.sort(this.sortByDataPrimeiroVencimento);
        this.financings_all = this.financings.sort(this.sortByDataPrimeiroVencimento);
      }
    },

    filterOnlyPaidTrigger() {
      console.log(this.filterOnlyPaid);
      if ( !this.filterOnlyPaid ) {
        this.financings = this.financings_all;
        this.totalizador = this.totalizador_all;
      }
      else {
        this.financings = [];
        this.totalizador = {
          total_investido: 0,
          total_acumulado: 0
        };

        for (const financing of this.financings_all) {
          if ( financing.dados.valorTotalInvestido > 0 ) {
            this.totalizador.total_investido = this.totalizador.total_investido + financing.dados.valorTotalInvestido;
            this.totalizador.total_acumulado = this.totalizador.total_acumulado + financing.dados.valorTotalAcumulado;

            this.financings.push(financing);
          }
        }
      }
    },

    sortByDataPrimeiroVencimento(objA, objB) {
      let a = moment(objA.dados.dataPrimeiroVencimento, "DD/MM/YYYY");
      let b = moment(objB.dados.dataPrimeiroVencimento, "DD/MM/YYYY");

      if ( this.sort == 'asc' ) {
        if (a < b) {
          return -1;
        }
        else if (a > b) {
          return 1;
        }
      }
      else {
        if (a > b) {
          return -1;
        }
        else if (a < b) {
          return 1;
        }
      }
      
      return 0;
    },

    sortByDataEncerramento(objA, objB) {
      let a = moment(objA.dados.dataEncerramento, "DD/MM/YYYY");
      let b = moment(objB.dados.dataEncerramento, "DD/MM/YYYY");

      if ( this.sort == 'asc' ) {
        if (a < b) {
          return -1;
        }
        else if (a > b) {
          return 1;
        }
      }
      else {
        if (a > b) {
          return -1;
        }
        else if (a < b) {
          return 1;
        }
      }
      
      return 0;
    },

    async getFinancings() {
      const financings = await financingService.findAll();
      for (const financing of financings) {
        var dataEncerramento = "Error";
        if ( financing.fields['Encerramento da Oferta'] && financing.fields['Encerramento da Oferta'].value ) {
          const dataEncerramentoMoment = moment(financing.fields['Encerramento da Oferta'].value, 'YYYY-MM-DD');
          dataEncerramento = dataEncerramentoMoment.format("DD/MM/YYYY");
        }

        var dataPrimeiroVencimento = "Error";
        if ( financing.fields['primeiro_vencimento'] && financing.fields['primeiro_vencimento'].value ) {
          const dataPrimeiroVencimentoMoment = moment(financing.fields['primeiro_vencimento'].value, 'YYYY-MM-DD');
          dataPrimeiroVencimento = dataPrimeiroVencimentoMoment.format("DD/MM/YYYY");
        }

        var quantidadeParcelas = "Error";
        if ( financing.fields['parcelas'] && financing.fields['parcelas'].value ) {
          quantidadeParcelas = financing.fields['parcelas'].value;
        }

        var valorTotalInvestido = 0;
        const orders = await ordersService.getOrdersByFinancingId(financing.id);
        valorTotalInvestido = orders.reduce( (sum, atual) => {
            if ( atual.status == 'Pago' ) {
              return sum + atual.item.value;
            }
            else {
              return sum;
            }
          }, 0,
        );

        var valorTotalAcumulado = this.remuneracaoAcumuladaGeral(valorTotalInvestido, financing) || 0;
        
        financing.dados = {
          nome: financing.name,
          dataEncerramento: dataEncerramento,
          dataPrimeiroVencimento: dataPrimeiroVencimento,
          quantidadeParcelas: quantidadeParcelas,
          valorTotalInvestido: valorTotalInvestido,
          valorTotalAcumulado: valorTotalAcumulado
        };

        this.totalizador.total_investido = this.totalizador.total_investido + financing.dados.valorTotalInvestido;
        this.totalizador.total_acumulado = this.totalizador.total_acumulado + financing.dados.valorTotalAcumulado;
      }
      this.financings = financings.sort(this.sortByDataPrimeiroVencimento);
      this.financings_all = financings.sort(this.sortByDataPrimeiroVencimento);
      this.totalizador_all = this.totalizador;

      this.loading = false;
    },

    download() {
      for ( const item of this.financings ) {
        this.items_exportar.push({
          nome: item.dados.nome,
          data_encerramento: item.dados.dataEncerramento,
          primeiro_vencimento: item.dados.dataPrimeiroVencimento,
          parcelas: item.dados.quantidadeParcelas,
          valor_investido: item.dados.valorTotalInvestido,
          valor_acumulado: item.dados.valorTotalAcumulado
        });
      }
      const data = XLSX.utils.json_to_sheet(this.items_exportar);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, 'data');
      XLSX.writeFile(wb, 'cwl.xlsx');
    },
  },
  watch: {
    financings() {
      this.setPages();
    },
    filterOnlyPaid() {
      this.filterOnlyPaidTrigger();
    }
  },
};
</script>

<template>
  <div class="annual-report">
    <card>
      <div class="report-filter">
        <h5 v-if="tipoPlataforma === tipoPlataformaType.P1">
        Vencimento Debit
        </h5>
        <h5 v-else-if="tipoPlataforma === tipoPlataformaType.P3">
        Vencimento CCBs
  </h5>
      </div>

      <div class="row">
        <div style="padding-top: 2px; padding-left: 20px;">
          <input
            name="filter_only_paid"
            ref="filter_only_paid"
            type="checkbox"
            v-model="filterOnlyPaid"
            style="cursor: pointer;"
          />
        </div>
        <div style="margin-left: 5px;">
          <label
            for="filter_only_paid"
            @click="filterOnlyPaidCheck"
          >
          Exibir somente lotes que tiveram aporte
          </label>
        </div>
      </div>

      <double-bounce v-if="loading"></double-bounce>

      <div class="row">
        <div class="col-12">
          <table class="table table-hover" v-if="!loading">
            <thead class="thead-light">
              <tr>
                <slot name="columns">
                  <th>Nome</th>
                  <th @click="orderByClicked('data_encerramento')" style="cursor: pointer;">
                    Data Encerramento
                    <i :class="'tim-icons ' + sortDataEncerramentoIcon"></i>
                  </th>
                  <th @click="orderByClicked('primeiro_vencimento')" style="cursor: pointer;">
                    Primeiro Vencimento
                    <i :class="'tim-icons ' + sortPrimeiroVencimentoIcon"></i>
                  </th>
                  <th>Parcelas</th>
                  <th>Valor Investido</th>
                  <th>Valor Acumulado</th>
                  <th>Ações</th>
                </slot>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in displayedData" :key="index">
                <td>
                  {{ item.dados.nome }}
                </td>
                <td>
                  {{ item.dados.dataEncerramento }}
                </td>
                <td>
                  {{ item.dados.dataPrimeiroVencimento }}
                </td>
                <td>
                  {{ item.dados.quantidadeParcelas }}
                </td>
                <td>
                  {{ item.dados.valorTotalInvestido | currency }}
                </td>
                <td>
                  {{ item.dados.valorTotalAcumulado | currency }}
                </td>
                <td>
                  <a
                    @click="viewRelatorioAnalitico(item.id)"
                    href="#"
                    title="Relatório Analítico"
                    class="button-action"
                  >
                    <i class="tim-icons icon-money-coins"></i>
                  </a>
                </td>
              </tr>
            </tbody>

            <tfoot class="thead-light" v-if="displayedData.length">
              <tr>
                <th colspan="4" style="text-align: center">Totais</th>
                <th>{{ totalizador.total_investido | currency }}</th>
                <th>{{ totalizador.total_acumulado | currency }}</th>
                <th></th>
              </tr>
            </tfoot>
          </table>

          <nav class="table-pagination">
            <ul class="pagination">
              <li class="page-item">
                <button
                  type="button"
                  class="page-link"
                  v-if="page != 1"
                  @click="page--"
                >
                  <i class="fas fa-angle-left"></i>
                </button>
              </li>

              <li class="page-item">
                <button
                  type="button"
                  class="page-link"
                  v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
                  :key="index"
                  @click="page = pageNumber"
                  :class="{ 'is-active': page === pageNumber }"
                >
                  {{ pageNumber }}
                </button>
              </li>

              <li class="page-item">
                <button
                  type="button"
                  class="page-link"
                  v-if="page < pages.length"
                  @click="page++"
                >
                  <i class="fas fa-angle-right"></i>
                </button>
              </li>
            </ul>
          </nav>

          <div class="row">
            <div class="export-button">
              <button
                class="btn btn-primary"
                @click="download"
                :disabled="!displayedData.length"
              >
                <i class="tim-icons icon-cloud-download-93"></i>
                Exportar para Excel
              </button>
            </div>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>
