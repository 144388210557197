  export const TIPO_PLATAFORMA = {
    P1: 'P1',
    P3: 'P3',
    DOACAO: 'DOACAO',
    Unknown: 'Unknown'
  };

const modalidade = process.env.VUE_APP_TYPE;
const submodalidade = process.env.VUE_APP_SUBTYPE;

export function getModalidadePlataforma() {
  if (modalidade === 'equity' && submodalidade.toString() === '588') {
    return TIPO_PLATAFORMA.P1;
  } else if (modalidade === 'equity' && submodalidade.toString() === '589') {
    return TIPO_PLATAFORMA.P3;
  } else if (modalidade === 'doacao') {
    return TIPO_PLATAFORMA.DOACAO;
  } else {
    return TIPO_PLATAFORMA.Unknown;
  }
}