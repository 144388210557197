<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import 'vue-file-agent/dist/vue-file-agent.css';

import Vue from 'vue';

import { cep } from '@/validate/customValidators.js';
import pessoasService from '@/services/Pessoas/pessoasService';
import statesService from '@/services/States/statesService';
import Card from '@/components/Cards/Card.vue';
import investorsService from '@/services/Investors/investorsService';
import attachmentsService from '@/services/Attachments/attachmentsService';
import logsService from '@/services/Logs/logsService';

import { vueTopprogress } from 'vue-top-progress';

import { quillEditor } from 'vue-quill-editor';

import FileUpload from 'vue-upload-component';
import ImageCompressor from '@xkeshi/image-compressor';

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect);

import {
  PessoaCaracteristica,
  CWLStatusInvestidor
} from '@/store/cwl_enums';

const limiteInvestidor4a = 1000000;
const limiteInvestidor4b = 200000;
const limiteInvestidor4c = 20000;
const calculoInvestidor4b = 0.1;

export default {
  components: { vueTopprogress, Card, quillEditor, FileUpload },
  data() {
    return {
      model: {
        states: [],
      },
      pessoa: {
        endereco: {
          estado: {}
        },
        dados_complementares: {
          dados_bancarios: {
            agencia: '',
            conta: '',
            banco: ''
          },
          approvalObservacao: '',
          perfil: {
            renda_bruta_anual: ''
          },
        },
      },
      isLoadingEnderecoPessoa: false,
      limite_investimento: '',
      stateSelected: {},
      submodalidade: process.env.VUE_APP_SUBTYPE,
      editorCuradoriaOption: {
        placeholder: 'Escreva aqui suas observações sobre a curadoria.',
      },
      selectedFilename: '',
      file: {
        item: {},
        name: 'Documento de Identificação'
      },
      fileChanged: false,

      // Documentos Anexos
      new_files: [],
      new_file_fields_shown: false,
      new_file_to_add: {},
      tipos_documentos: [],
      perfil_documentos: [],
      accept: 'image/png,image/jpeg,image/jpg,application/pdf',
      extensions: 'jpg,jpeg,png',
      minSize: 1024,
      multiple: false,
      directory: false,
      drop: false,
      dropDirectory: false,
      createDirectory: false,
      addIndex: false,
      thread: 3,
      show_alerta_salvar_documentos: false,
      perfil_documentos_excluidos: [],

      Deselect: {
        render: (createElement) => createElement('span', '❌'),
      },
      lista_genero: [
        {
          codigo: "F",
          descricao: "Feminino"
        },
        {
          codigo: "M",
          descricao: "Masculino"
        }
      ],
      lista_estado_civil: [
        {
            codigo: "C",
            descricao: "Casado"
        },
        {
            codigo: "S",
            descricao: "Solteiro"
        },
        {
            codigo: "V",
            descricao: "Viúvo"
        },
        {
            codigo: "Q",
            descricao: "Desquitado"
        },
        {
            codigo: "D",
            descricao: "Divorciado"
        },
        {
            codigo: "N",
            descricao: "Não Informado"
        },
        {
            codigo: "J",
            descricao: "Separado Judicialmente"
        },
        {
            codigo: "U",
            descricao: "União Estável"
        },
        {
            codigo: "O",
            descricao: "Outros"
        },
      ],
    };
  },
  validations: {
    pessoa: {
      endereco: {
        cep: {
          valid: cep,
        },
      },
    },
  },
  async mounted() {
    await this.getTipoDocumentos();
    await this.getStates();
    this.pessoa = this.$attrs.pessoa;
    await this.getPerfilDocumentos();
    this.setAddressStateSelected();

    this.model = this.$attrs.model || this.model;
    this.updateLimiteInvestimento();
  },
  computed: {
    investor() {
      return this.$store.getters.isLoggedIn;
    },
    pessoaId() {
      return this.$route.params.id;
    },
    currentPessoa() {
      return this.$store.getters.getPessoa;
    },
    role() {
      const value = this.$store.getters.userClaim
        ? this.$store.getters.userClaim.role
        : '';
      return value.toLowerCase();
    },
    isGestor() {
      return this.role == PessoaCaracteristica.Gestor;
    },
    isCurador() {
      return this.role == PessoaCaracteristica.Curador;
    },
    isGestorOrCurador() {
      return this.isCurador || this.isGestor;
    },
    isUsuario() {
      return this.role == PessoaCaracteristica.InvestorRole;
    },
    CWLStatusInvestidor() {
      return CWLStatusInvestidor;
    }
  },
  methods: {
    onEditorBlur(quill) {},
    onEditorFocus(quill) {},
    onEditorReady(quill) {},
    onCuradoriaEditorChange({ quill, html, text }) {
      if (!this.pessoa.dados_complementares) {
        this.$set(this.pessoa, 'dados_complementares', {});
      }
      this.pessoa.dados_complementares.approvalObservacao = html;
    },
    async getTipoDocumentos() {
      this.tipos_documentos = await attachmentsService.getTipoDocumentos();
    },

    removerPerfilDocumento(index) {
      this.perfil_documentos_excluidos.push(this.perfil_documentos[index]);
      this.perfil_documentos.splice(index, 1);
      this.show_alerta_salvar_documentos = true;
    },

    async fileUploadInputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }
        // Filter php html js file
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
        // Automatic compression
        if (newFile.file && newFile.error === "" && newFile.type.substr(0, 6) === 'image/' && this.autoCompress > 0 && this.autoCompress < newFile.size) {
          newFile.error = 'compressing'
          const imageCompressor = new ImageCompressor(null, {
            convertSize: 1024 * 1024,
            maxWidth: 512,
            maxHeight: 512,
          })
          imageCompressor.compress(newFile.file)
            .then((file) => {
              this.$refs.upload.update(newFile, { error: '', file, size: file.size, type: file.type })
            })
            .catch((err) => {
              this.$refs.upload.update(newFile, { error: err.message || 'compress' })
            })
        }
      }
      if (newFile && newFile.error === "" && newFile.file && (!oldFile || newFile.file !== oldFile.file)) {
        // Create a blob field
        newFile.blob = ''
        let URL = (window.URL || window.webkitURL)
        if (URL) {
          newFile.blob = URL.createObjectURL(newFile.file)
        }
        // Thumbnails
        newFile.thumb = ''
        if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
          newFile.thumb = newFile.blob
        }
      }
      if (newFile && newFile.error === '' && newFile.type === "application/pdf" && newFile.blob && (!oldFile || newFile.blob !== oldFile.blob)) {
        newFile.contentType = "application/pdf";
        let perfil_documento = {
          file: newFile,
          name: newFile.name,
          tipo_documento: this.new_file_to_add,
          status: "Aguardando Envio"
        };
        this.perfil_documentos.push(perfil_documento);
        this.show_alerta_salvar_documentos = true;
      }
      // image size
      if (newFile && newFile.error === '' && newFile.type.substr(0, 6) === "image/" && newFile.blob && (!oldFile || newFile.blob !== oldFile.blob)) {
        let perfil_documento = {
          file: newFile,
          name: newFile.name,
          tipo_documento: this.new_file_to_add,
          status: "Aguardando Envio"
        };
        this.perfil_documentos.push(perfil_documento);
        this.show_alerta_salvar_documentos = true;

        newFile.error = 'image parsing'
        let img = new Image();
        img.onload = () => {
          this.$refs.upload.update(newFile, {error: '', height: img.height, width: img.width})
        }
        img.οnerrοr = (e) => {
          this.$refs.upload.update(newFile, { error: 'parsing image size'})
        }
        img.src = newFile.blob
      }
    },

    async fileUploadInputFile(newFile, oldFile) {
    },

    async reprovarCadastro() {
      this.$refs.topProgress.start();

      this.pessoa.dados_complementares.approvalStatus = CWLStatusInvestidor.Reprovado;

      try {
        await pessoasService.updatePessoaBy(this.pessoa, {
          email: this.pessoa.email.email,
        });
        await investorsService.enviarEmailAtualizacaoCadastroReprovado(this.pessoa);

        this.$notify({
          title: 'Investidor reprovado! Ele receberá um e-mail com esta informação.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        this.$emit('cadastroReprovado', CWLStatusInvestidor.Reprovado);
      }
      catch (e) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.$refs.topProgress.done();
    },
    async aprovarCadastro() {
      this.$refs.topProgress.start();

      this.pessoa.dados_complementares.approvalStatus = CWLStatusInvestidor.Aprovado;

      try {
        await pessoasService.updatePessoaBy(this.pessoa, {
          email: this.pessoa.email.email,
        });
        await investorsService.enviarEmailAtualizacaoCadastroAprovado(this.pessoa);

        this.$notify({
          title: 'Investidor aprovado! Ele receberá um e-mail com esta informação.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success',
          timeout: 3000,
        });

        this.$emit('cadastroAprovado', CWLStatusInvestidor.Aprovado, this.pessoa);
        
      }
      catch (e) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.$refs.topProgress.done();
    },
    goToDocuments() {
      const pessoa = { ...this.pessoa };
      pessoa.endereco.estado = this.stateSelected;
      this.$emit('saveProfileInformations', { pessoa: pessoa, goToDocuments: true });
    },
    async saveProfileInformations() {
      await this.salvarPerfilDocumentos();
    },

    async asyncForEach(array, callback) {
      if ( array ) {
        for (let index = 0; index < array.length; index++) {
          await callback(array[index], index, array);
        }
      }
    },

    async salvarPerfilDocumentos() {
      this.$refs.topProgress.start();
      let that = this;
      var email = this.currentPessoa.email.email;
      if ( this.pessoaId ) {
        let pessoa = await pessoasService.findPessoaById(this.pessoaId);
        email = pessoa.email.email;
      }

      var errors = 0;
      const run = async () => {
        const pessoa = { ...that.pessoa };
        pessoa.endereco.estado = that.stateSelected;

        if ( !that.show_alerta_salvar_documentos ) {
          var documentFilled = true;
          await that.asyncForEach(that.tipos_documentos, async (tipoDocumento) => {
            if ( tipoDocumento.obrigatorio && documentFilled ) {
              var temEsseDocumento = false;
              await that.asyncForEach(pessoa.perfil_documentos, async (perfilDocumento) => {
                if ( perfilDocumento.tipo_documento.nome == tipoDocumento.nome ) {
                  temEsseDocumento = true;
                }
              });
              documentFilled = temEsseDocumento;
            }
          });
          that.$emit('saveProfileInformations', { pessoa: pessoa, goToDocuments: false, documentFilled: documentFilled });
          that.$refs.topProgress.done();
          return;
        }
        else {
          that.$refs.btnSalvar.disabled = true;
          await that.asyncForEach(that.perfil_documentos_excluidos, async (perfilDocumento) => {
            await attachmentsService.deleteAttachment(perfilDocumento.file.name, 'InvestorDocuments', email);
          });

          let pessoa_perfil_documentos = [];
          await that.asyncForEach(that.perfil_documentos, async (perfilDocumento) => {
            let nomeDocumento = perfilDocumento.tipo_documento.nome + "_" + perfilDocumento.file.id;
            try {
              if ( perfilDocumento.status != "Enviado" ) {
                perfilDocumento.status = "Enviando...";
                await attachmentsService.addNewDocumentAttachment(perfilDocumento.file.file, nomeDocumento, 'InvestorDocuments', email);
                perfilDocumento.status = "Enviado";
              }

              let pessoaPerfilDocumento = {
                id: perfilDocumento.file.id,
                tipo_documento: perfilDocumento.tipo_documento,
                storage_nome_arquivo: nomeDocumento
              };
              pessoa_perfil_documentos.push(pessoaPerfilDocumento);
            }
            catch (e) {
              errors++;
              perfilDocumento.status = "Erro ao enviar";
            }
          });

          var documentFilled = true;
          await that.asyncForEach(that.tipos_documentos, async (tipoDocumento) => {
            if ( tipoDocumento.obrigatorio && documentFilled ) {
              var temEsseDocumento = false;
              await that.asyncForEach(pessoa_perfil_documentos, async (perfilDocumento) => {
                if ( perfilDocumento.tipo_documento.nome == tipoDocumento.nome ) {
                  temEsseDocumento = true;
                }
              });
              documentFilled = temEsseDocumento;
            }
          });

          that.$refs.btnSalvar.disabled = false;

          if ( errors > 0 ) {
            that.$notify({
              title: 'Ooopss',
              message: 'Desculpe, ocorreu um erro ao salvar os documentos. Tente novamente.',
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'warning',
              timeout: 3000,
            });
          }
          else {
            that.show_alerta_salvar_documentos = false;
            pessoa.perfil_documentos = pessoa_perfil_documentos;
            that.$emit('saveProfileInformations', { pessoa: pessoa, goToDocuments: false, documentFilled: documentFilled });
          }
        }

        that.$refs.topProgress.done();
      }
      run();
    },
    setAddressStateSelected() {
      this.stateSelected = {};

      if (!this.pessoa.endereco) return;

      const estado = this.pessoa.endereco.estado;

      if (typeof estado == 'string') {
        for (let index = 0; index < this.model.states.length; index++) {
          const _state = this.model.states[index];
          if (_state.uf == estado) {
            this.stateSelected = _state;
            break;
          }
        }
      } else if (estado.uf) {
        this.stateSelected = estado;
      }
    },
    async getStates() {
      this.model.states = await statesService.getAllStates();
    },
    async changeCEP() {
      if (!this.$v.pessoa.endereco.cep.valid || this.isLoadingEnderecoPessoa)
        return;

      if (!this.isLoadingEnderecoPessoa) {
        this.isLoadingEnderecoPessoa = true;
        try {
          const enderecoFound = await pessoasService.searchAddressByZipcode(
            this.pessoa.endereco.cep,
          );
          this.pessoa.endereco = Object.assign(
            this.pessoa.endereco || {},
            enderecoFound || {},
          );
          this.setAddressStateSelected();
        } catch (error) {
          console.error(error);
        }
        this.isLoadingEnderecoPessoa = false;
      }
    },
    selectedFile() {
      this.selectedFilename = this.$refs.uploadFile.files[0].name;
      this.fileChanged = true;
    },
    clickFileInput() {
      this.$refs.uploadFile.click();
    },
    async getPerfilDocumentos() {
      if ( this.pessoaId ) {
        this.pessoa = await pessoasService.findPessoaById(this.pessoaId);
      }
      var email = this.currentPessoa.email.email;
      if ( this.pessoaId ) {
        email = this.pessoa.email.email;
      }
      const result = await attachmentsService.getUserDocuments(
        'InvestorDocuments',
        email,
      );

      let cnh_to_use_list = this.tipos_documentos.filter(item => {
        return item.chave == "cnh";
      });
      let cnh_to_use = cnh_to_use_list[0];

      let that = this;
      result.items.map(async (item) => {
        if ( item.name == "Documento de Identificação" || item.name == "Documento de Identificação.jpeg" ) {
          let pessoas_documento_antigo = (that.pessoa.perfil_documentos ? that.pessoa.perfil_documentos : []).filter(item => {
            return item.storage_nome_arquivo == "Documento de Identificação" || item.storage_nome_arquivo == "Documento de Identificação.jpeg";
          });
          if ( pessoas_documento_antigo.length == 0 ) {
            if ( that.pessoa.perfil_documentos == undefined ) {
              that.pessoa.perfil_documentos = [];
            }
            that.pessoa.perfil_documentos.push({
              id: cnh_to_use.id,
              storage_nome_arquivo: item.name,
              tipo_documento: cnh_to_use
            });
          }
        }
        if ( that.pessoa.perfil_documentos ) {
          await that.asyncForEach(that.pessoa.perfil_documentos, async (pessoaPerfilDocumento) => {
            if ( pessoaPerfilDocumento.storage_nome_arquivo == item.name ) {
              let metadata = await item.getMetadata();

              const path = 'InvestorDocuments/' + email;
              let url = await attachmentsService.urlAttachment(item.name, path);
              let base64 = await attachmentsService.base64Attachment(item.name, path);

              var binaryData = [];
              binaryData.push(base64);

              let thumb = url;
              let perfilDocumento = {
                file: {
                  id: pessoaPerfilDocumento.id,
                  thumb: thumb,
                  file: new Blob(binaryData, { type: metadata.contentType }),
                  name: item.name,
                  contentType: metadata.contentType
                },
                tipo_documento: {
                  nome: pessoaPerfilDocumento.tipo_documento.nome
                },
                status: "Enviado"
              };
              that.perfil_documentos.push(perfilDocumento);
            }
          });
        }
      });
    },
    async getUserDocument() {
      if ( this.pessoaId ) {
        this.pessoa = await pessoasService.findPessoaById(this.pessoaId);
      }

      var email = this.currentPessoa.email.email;
      if ( this.pessoaId ) {
        email = this.pessoa.email.email;
      }
      const result = await attachmentsService.getUserDocuments(
        'InvestorDocuments',
        email,
      );

      result.items.map((item) => {
        if ( item.name == 'Documento de Identificação' ) {
          this.file.item = item;
          this.selectedFilename = item.name;
        }
      });
    },
    async downloadDocument(perfilDocumento) {
      if ( perfilDocumento.status != "Enviado" ) {
        return;
      }
      var nome = "";
      if ( perfilDocumento.tipo_documento && perfilDocumento.tipo_documento.nome && perfilDocumento.tipo_documento.id ) {
        nome = perfilDocumento.tipo_documento.nome + "_" + perfilDocumento.tipo_documento.id;
      }
      else {
        nome = perfilDocumento.file.name;
      }

      let name = (perfilDocumento.file.name == "Documento de Identificação" || perfilDocumento.file.name == "Documento de Identificação.jpeg") ? perfilDocumento.file.name : nome;
      this.$refs.topProgress.start();

      var email = this.currentPessoa.email.email;
      if ( this.pessoaId ) {
        email = this.pessoa.email.email;
      }

      var type = undefined;
      if ( perfilDocumento.file.contentType == "application/pdf" ) {
        type = ".pdf";
      }
      else if ( perfilDocumento.file.contentType == "image/png" ) {
        type = ".png";
      }
      else if ( perfilDocumento.file.contentType == "image/jpeg" ) {
        type = ".jpeg";
      }
      else if ( perfilDocumento.file.contentType == "image/jpg" ) {
        type = ".jpg";
      }

      try {
        const path = 'InvestorDocuments/' + email;
        await attachmentsService.downloadAttachment(name, path, type);
      } catch (e) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.$refs.topProgress.done();
    },
    async downloadItem() {
      this.$refs.topProgress.start();

      const item = this.file.item;
      var email = this.currentPessoa.email.email;
      if ( this.pessoaId ) {
        email = this.pessoa.email.email;
      }

      try {
        const path = 'InvestorDocuments/' + email;
        await attachmentsService.downloadAttachment(item.name, path);
      } catch (e) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.$refs.topProgress.done();
    },
    async addNewAttachment() {
      this.$refs.topProgress.start();
      const file = await this.$refs.uploadFile.files[0];
      if ( !file ) {
        const pessoa = { ...this.pessoa };
        pessoa.endereco.estado = this.stateSelected;
        var documentFilled = false;
        if ( this.file.item.name ) {
          documentFilled = true;
        }
        this.$emit('saveProfileInformations', { pessoa: pessoa, goToDocuments: false, documentFilled: documentFilled });
        this.$refs.topProgress.done();
        return;
      }

      var email = this.currentPessoa.email.email;
      if ( this.pessoaId ) {
        let pessoa = await pessoasService.findPessoaById(this.pessoaId);
        email = pessoa.email.email;
      }

      try {
        const logUser = {
            user: {
                email: email
            }
        };
        logsService.createNewLog(logUser, '', 'Adicionar Documento Identificação', '', 'Documento de Identificação');
        await attachmentsService.addNewDocumentAttachment(file, 'Documento de Identificação', 'InvestorDocuments', email);

        const pessoa = { ...this.pessoa };
        pessoa.endereco.estado = this.stateSelected;
        this.$emit('saveProfileInformations', { pessoa: pessoa, goToDocuments: false, documentFilled: true });
      } catch (e) {
        this.$notify({
          title: 'Ooopss',
          message: 'Desculpe, ocorreu um erro. Tente novamente.',
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'warning',
          timeout: 3000,
        });
      }

      this.$refs.topProgress.done();
    },
    /* eslint-disable max-len */

    updateLimiteInvestimento() {
      // let pessoa = this.pessoa;
      // let limiteValidado = false;
      // if (pessoa.dados_complementares.perfil && pessoa.dados_complementares.perfil.investimentos_financeiros > limiteInvestidor4a) {
      //   // Anexo 4A
      //   limiteValidado = true;
      //   this.limite_investimento = 'Ilimitado';
      // } else if (pessoa.dados_complementares.perfil && (pessoa.dados_complementares.perfil.renda_bruta_anual > limiteInvestidor4b || pessoa.dados_complementares.perfil.investimentos_financeiros > limiteInvestidor4b)) {
      //   // Anexo 4B
      //   var valorParaCalculo = pessoa.dados_complementares.perfil.renda_bruta_anual;
      //   if (pessoa.dados_complementares.perfil.investimentos_financeiros > pessoa.dados_complementares.perfil.renda_bruta_anual) {
      //     valorParaCalculo = pessoa.dados_complementares.perfil.investimentos_financeiros;
      //   }
      //   var valorCalculado = valorParaCalculo * calculoInvestidor4b;
      //   var valorJaInvestido = pessoa.dados_complementares.perfil.investimentos_outras_plataformas + pessoa.dados_complementares.perfil.investimentos_nesta_plataforma;
      //   var valorDisponivelPermitido = valorCalculado - valorJaInvestido;

      //   let valor = this.order.item.order.totalValue || 0;
      //   limiteValidado = limiteInvestimento(0, valor, valorDisponivelPermitido);

      //   const limiteInvestimentoPreFormatado = parseFloat(valorDisponivelPermitido > 0 ? valorDisponivelPermitido : 0).toFixed(2).replace('.', ',');
      //   const limiteInvestimentoFormatado = 'R$ ' + limiteInvestimentoPreFormatado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      //   this.limite_investimento = limiteInvestimentoFormatado;
      //   this.limite_investimento_puro = valorDisponivelPermitido;
      // } else {
      //   // Anexo 4C
      //   var valorSomado = 0;
      //   if (pessoa.dados_complementares.perfil) {
      //     if (pessoa.dados_complementares.perfil.investimentos_outras_plataformas) {
      //       valorSomado = valorSomado + pessoa.dados_complementares.perfil.investimentos_outras_plataformas;
      //     }
      //     if (pessoa.dados_complementares.perfil.investimentos_nesta_plataforma) {
      //       valorSomado = valorSomado + pessoa.dados_complementares.perfil.investimentos_nesta_plataforma;
      //     }
      //   }
      //   var valorJaInvestidoC = limiteInvestidor4c - valorSomado;

      //   const limiteInvestimentoPreFormatado = parseFloat(valorJaInvestidoC > 0 ? valorJaInvestidoC : 0).toFixed(2).replace('.', ',');
      //   const limiteInvestimentoFormatado = 'R$ ' + limiteInvestimentoPreFormatado.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      //   this.limite_investimento = limiteInvestimentoFormatado;
      //   this.limite_investimento_puro = valorJaInvestidoC;
      // }


      //ANTIGO CODIGO \/
      
      const rendaBruta = this.pessoa.dados_complementares.perfil
        .renda_bruta_anual
        ? this.pessoa.dados_complementares.perfil.renda_bruta_anual
        : 0;
      const investimentosFinanceiros = this.pessoa.dados_complementares.perfil
        .investimentos_financeiros
        ? this.pessoa.dados_complementares.perfil.investimentos_financeiros
        : 0;
      const investimentosNestaPlataforma = this.pessoa.dados_complementares
        .perfil.investimentos_nesta_plataforma
        ? this.pessoa.dados_complementares.perfil.investimentos_nesta_plataforma
        : 0;
      const investimentosOutrasPlataformas = this.pessoa.dados_complementares
        .perfil.investimentos_outras_plataformas
        ? this.pessoa.dados_complementares.perfil
            .investimentos_outras_plataformas
        : 0;

      if (investimentosFinanceiros > 1000000) {
        this.limite_investimento = 'Ilimitado';
      } else if (investimentosFinanceiros > 200000 || rendaBruta > 200000) {
        let calculo10Porcento = 0;
        if (investimentosFinanceiros > rendaBruta) {
          calculo10Porcento = investimentosFinanceiros * 0.1;
        } else {
          calculo10Porcento = rendaBruta * 0.1;
        }
        const montante =
          investimentosNestaPlataforma + investimentosOutrasPlataformas;
        const limiteInvestimentoPuro = calculo10Porcento - montante;

        if(limiteInvestimentoPuro < 0){
          this.limite_investimento = "R$ 0,00"
          return
        }

        const limiteInvestimentoPreFormatado = parseFloat(
          limiteInvestimentoPuro,
        )
          .toFixed(2)
          .replace('.', ',');
        const limiteInvestimento =
          'R$ ' +
          limiteInvestimentoPreFormatado
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        this.limite_investimento = limiteInvestimento;
      } else {
        const montante =
          investimentosNestaPlataforma + investimentosOutrasPlataformas;
        const limiteInvestimentoPuro = 20000 - montante;

        console.log(limiteInvestimentoPuro);



        if(limiteInvestimentoPuro < 0){
          this.limite_investimento = "R$ 0,00"
          return
        }

        const limiteInvestimentoPreFormatado = parseFloat(
          limiteInvestimentoPuro,
        )
          .toFixed(2)
          .replace('.', ',');
        const limiteInvestimento =
          'R$ ' +
          limiteInvestimentoPreFormatado
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        this.limite_investimento = limiteInvestimento;
      }
    },
  },
  watch: {
    // Observando para garantir que 'dados_complementares' sempre existe e é reativo
    pessoa: {
      handler(newVal) {
        if (!newVal.dados_complementares) {
          this.$set(newVal, 'dados_complementares', {});
        }
        if (!newVal.dados_complementares.dados_bancarios) {
          this.$set(newVal.dados_complementares, 'dados_bancarios', {});
        }
        if (!newVal.dados_complementares.perfil) {
          this.$set(newVal.dados_complementares, 'perfil', {});
        }
      },
      deep: true,
      immediate: true
    }
  },
};
</script>

<template>
  <form @submit.prevent="saveProfileInformations()">
    <card>
      <h5 slot="header" class="title mt-3">Dados Complementares</h5>
      <div class="row">
        <div class="col-md-3 pr-md-1">
          <base-input
            v-model="pessoa.dados_complementares.rg"
            label="RG*"
            :boldLabel="true"
            placeholder="Informe seu RG"
            required
          ></base-input>
        </div>
        <div class="col-md-3 pr-md-1">
          <base-input
            v-model="pessoa.dados_complementares.rg_orgao_expeditor"
            label="Órgão Expedidor*"
            :boldLabel="true"
            placeholder="Informe o órgão expedidor"
            required
          ></base-input>
        </div>
        <div class="col-md-3 pr-md-1">
          <base-input
            v-model="pessoa.dados_complementares.rg_uf_orgao_expeditor"
            label="UF Órgão Expedidor*"
            :boldLabel="true"
            placeholder="Informe o UF"
            required
          ></base-input>
        </div>
        <div class="col-md-3 pr-md-1">
          <base-input
            v-model="pessoa.dados_complementares.rg_data_emissao"
            label="Data de Emissão*"
            :boldLabel="true"
            placeholder="Informe a data de emissão"
            required
            v-mask="['##/##/####']"
          ></base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3 pr-md-1">
          <base-input
            v-model="pessoa.dados_complementares.nome_mae"
            label="Nome da mãe*"
            :boldLabel="true"
            placeholder="Informe o nome da mãe"
            required
          ></base-input>
        </div>
        <div class="col-md-3 pr-md-1">
          <base-input
            v-model="pessoa.dados_complementares.naturalidade"
            label="Naturalidade*"
            :boldLabel="true"
            placeholder="Informe sua naturalidade"
            required
          ></base-input>
        </div>
        <div class="col-md-3 pr-md-1">
          <base-input
            required
            label="Estado Civil*"
            :boldLabel="true"
          >
            <v-select
              label="descricao"
              class="style-chooser"
              :options="lista_estado_civil"
              v-model="pessoa.dados_complementares.estado_civil"
              :components="{ Deselect }"
            ></v-select>
          </base-input>
        </div>
        <div class="col-md-3 pl-md-1">
          <base-input
            required
            label="Gênero*"
            :boldLabel="true"
          >
            <v-select
              label="descricao"
              class="style-chooser"
              :options="lista_genero"
              v-model="pessoa.dados_complementares.genero"
              :components="{ Deselect }"
            ></v-select>
          </base-input>
        </div>
      </div>

      <h5 class="title mt-3">Endereço</h5>
      <div class="row">
        <div class="col-md-12 pr-md-1">
          <div class="form-group">
            <base-input
              label="CEP"
              :boldLabel="true"
              v-mask="'#####-###'"
              placeholder="Digite seu CEP"
              v-model="pessoa.endereco.cep"
              v-on:input="changeCEP"
              required
            ></base-input>
            <label v-if="isLoadingEnderecoPessoa" style="cursor: default"
              ><i>Pesquisando...</i></label
            >
            <div
              class="error"
              v-if="pessoa.endereco.cep && !$v.pessoa.endereco.cep.valid"
            >
              Informe um CEP válido.
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Número"
            :boldLabel="true"
            placeholder="Digite seu Número"
            v-mask="['########']"
            v-model="pessoa.endereco.numero"
            required
          ></base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Complemento"
            :boldLabel="true"
            placeholder="Digite seu Complemento"
            v-model="pessoa.endereco.complemento"
          ></base-input>
        </div>
        <div class="col-md-4 pl-md-1">
          <base-input
            label="Bairro"
            :boldLabel="true"
            v-model="pessoa.endereco.bairro"
            placeholder="Digite seu Bairro"
            required
          ></base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Rua"
            :boldLabel="true"
            v-model="pessoa.endereco.rua"
            placeholder="Digite sua Rua"
            required
          >
          </base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Cidade"
            :boldLabel="true"
            placeholder="Digite sua Cidade"
            v-model="pessoa.endereco.cidade"
            required
          ></base-input>
        </div>
        <div class="col-md-4 pl-md-1" v-if="stateSelected.uf">
          <base-input
            required
            label="Estado"
            :boldLabel="true"
          >
            <v-select
              label="name"
              class="style-chooser"
              :options="model.states"
              v-model="stateSelected"
              :components="{ Deselect }"
            ></v-select>
          </base-input>
        </div>
      </div>

      <h5 class="title mt-3">Profissão</h5>

      <div class="row">
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Ocupação"
            :boldLabel="true"
            placeholder="Digite sua Ocupação"
            v-model="pessoa.dados_complementares.ocupacao"
            required
          ></base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Nome da Empresa (Opcional)"
            :boldLabel="true"
            placeholder="Digite o nome de sua Empresa"
            v-model="pessoa.dados_complementares.nome_empresa"
          ></base-input>
        </div>
        <div class="col-md-4 pl-md-1">
          <base-input
            label="Cargo (Opcional)"
            :boldLabel="true"
            placeholder="Digite seu Cargo"
            v-model="pessoa.dados_complementares.cargo"
          ></base-input>
        </div>
      </div>

      <h5 class="title mt-3">Dados Bancários</h5>

      <div class="row">
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Agência sem dígito*"
            :boldLabel="true"
            placeholder="Digite sua Agência"
            v-model="pessoa.dados_complementares.dados_bancarios.agencia"
            required
          ></base-input>
        </div>
        <div class="col-md-4 pr-md-1">
          <base-input
            label="Conta com dígito*"
            :boldLabel="true"
            placeholder="Digite sua Conta"
            v-model="pessoa.dados_complementares.dados_bancarios.conta"
            required
          ></base-input>
        </div>
        <div class="col-md-4 pl-md-1">
          <base-input
            required
            label="Banco*"
            :boldLabel="true"
          >
            <v-select
              label="name"
              class="style-chooser"
              :options="model.banks"
              v-model="pessoa.dados_complementares.dados_bancarios.banco"
              :components="{ Deselect }"
            ></v-select>
          </base-input>
        </div>
      </div>

      <h5 class="title mt-3">Perfil de Investimento</h5>

      <div class="row">
        <div class="col-md-12">
          <base-input
            required
            label="Qual situação melhor se encaixa ao seu perfil atual em relação a investimentos? (opcional)"
            :boldLabel="true"
          >
            <select
              class="form-control"
              v-model="pessoa.dados_complementares.perfil.perfil"
            >
              <option value="" disabled>Selecionar</option>
              <option
                v-for="profile in model.profiles"
                :key="profile.id"
                :value="profile.profile"
              >
                {{ profile.profile }}
              </option>
            </select>
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <base-input
            label="Qual o principal segmento você tem interesse em investir? (opcional)"
            :boldLabel="true"
          >
            <select
              class="form-control"
              v-model="pessoa.dados_complementares.perfil.segmento"
            >
              <option value="" disabled>Selecionar</option>
              <option
                v-for="segment in model.segments"
                :key="segment.id"
                :value="segment.name"
              >
                {{ segment.name }}
              </option>
            </select>
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <base-input
            label="Quanto do seu patrimônio você está disposto a investir? (opcional)"
            :boldLabel="true"
          >
            <select
              class="form-control"
              v-model="pessoa.dados_complementares.perfil.valor"
            >
              <option value="" disabled>Selecionar</option>
              <option
                v-for="value in model.values"
                :key="value.id"
                :value="value.values"
              >
                {{ value.values }}
              </option>
            </select>
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 mb-1">
          <base-checkbox
            v-model="pessoa.dados_complementares.aparecer_investidor"
          >
            Concordo em aparecer como investidor
          </base-checkbox>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 mb-3">
          <base-checkbox
            v-model="pessoa.dados_complementares.politicamente_exposto"
          >
            Sou uma pessoa politicamente exposta
          </base-checkbox>
        </div>
      </div>

      <div class="row" v-if="submodalidade == '588'">
        <div class="col-md-12">
          <label for="other-investments" style="font-weight: bold;">{{ $t('profile.otherInvestments') }}</label>
          <currency-input
            id="other-investments"
            class="form-control"
            v-model="
              pessoa.dados_complementares.perfil
                .investimentos_outras_plataformas
            "
            placeholder="Informe o valor dos Investimentos"
            currency="BRL"
            locale="pt-br"
            v-on:focusout="updateLimiteInvestimento"
            :auto-decimal-mode="true"
            :readonly="isGestorOrCurador"
          />
        </div>
      </div>

      <div class="row" v-if="submodalidade == '588'">
        <div class="col-md-12">
          <label for="investment-this-platform" style="font-weight: bold;">{{ $t('profile.investmentThisPlatform') }}</label>
          <currency-input
            readonly
            id="investment-this-platform"
            class="form-control"
            v-model="
              pessoa.dados_complementares.perfil.investimentos_nesta_plataforma
            "
            placeholder="R$ 0,00"
            currency="BRL"
            locale="pt-br"
            :auto-decimal-mode="true"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <label for="annual-gross" v-if="submodalidade == '588'" style="font-weight: bold;">{{ $t('profile.annualGross.588') }}</label>
          <label for="annual-gross" v-if="submodalidade == 'bacen'" style="font-weight: bold;">{{ $t('profile.annualGross.bacen') }}</label>

          <currency-input
            id="annual-gross"
            class="form-control"
            v-model="pessoa.dados_complementares.perfil.renda_bruta_anual"
            :placeholder="submodalidade == '588' ? $t('profile.annualGross.588') : $t('profile.annualGross.bacen')"
            currency="BRL"
            locale="pt-br"
            :auto-decimal-mode="true"
            v-on:focusout="updateLimiteInvestimento"
            required
          />
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <label for="financial-investments" v-if="submodalidade == '588'" style="font-weight: bold;">{{ $t('profile.financialInvestments.588') }}</label>
          <label for="financial-investments" v-if="submodalidade == 'bacen'" style="font-weight: bold;">{{ $t('profile.financialInvestments.bacen') }}</label>

          <currency-input
            id="financial-investments"
            class="form-control"
            v-model="pessoa.dados_complementares.perfil.investimentos_financeiros"
            :placeholder="submodalidade == '588' ? $t('profile.financialInvestments.588') : $t('profile.financialInvestments.bacen')"
            currency="BRL"
            locale="pt-br"
            :auto-decimal-mode="true"
            v-on:focusout="updateLimiteInvestimento"
            required
          />
        </div>
      </div>

      <div class="row" v-if="submodalidade == '588'">
        <div class="col-md-12">
          <base-input
            label="Limite Atual de Investimento"
            :boldLabel="true"
            placeholder=""
            v-model="limite_investimento"
            readonly
          ></base-input>
        </div>
      </div>

      <!-- <div class="row">
        <div class="col-md-12">
          <label class="form-label" style="font-weight: bold;">Documento de Identificação</label> <br>
            <button type="button" class="btn btn-sm" v-if="file.item.name" @click="clickFileInput">
              Alterar
            </button>
            <button type="button" class="btn btn-sm btn-warning" v-if="!file.item.name" @click="clickFileInput">
              Selecionar documento obrigatório
            </button>
            <span style="font-style: italic;">
              RG + CPF ou CNH (carregar foto self, segurando o documento de identificação ao lado do rosto)
            </span>
            <button type="button" class="btn btn-sm btn-dark" v-if="file.item.name" @click="downloadItem">
              Download
            </button>
            <span v-if="fileChanged" style="font-style: italic; font-size: 12px; padding-top: 5px;">Selecionado! Não esqueça de clicar em Salvar.</span>
          <input style="opacity: 0; height: 0px; width: 0px; opacity:0; position:absolute; left: 0; top: 0;" class="form-control" type="file" ref="uploadFile" id="formFile" @change="selectedFile()" accept="image/*, .pdf" tabindex="-1">
        </div>
      </div> -->

      <br />
      <div class="row">
        <div class="col-md-12">
          <h5><b>DOCUMENTOS</b></h5>
        </div>
      </div>
      <div class="row" v-if="!new_file_fields_shown">
        <div class="col-md-3">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            fill
            @click="new_file_fields_shown = true;"
          >
            Adicionar Documentos
          </button>
        </div>
      </div>
      <div class="row" v-if="new_file_fields_shown">
        <div class="col-md-12">
          <base-input label="Tipo de Documento" style="font-weight: bold;">
            <select
              class="form-control col-md-3"
              v-model="new_file_to_add"
            >
              <option
                :value="tipo_documento"
                v-for="(tipo_documento, index) in tipos_documentos"
                :key="index"
              >
                {{ tipo_documento.nome }}
              </option>
            </select>
          </base-input>
          <span><i>{{ new_file_to_add.orientacao }}</i></span>
        </div>
      </div>
      <div class="row" v-if="new_file_fields_shown && new_file_to_add.nome">
        <div class="col-md-3">
          <file-upload
            class="btn btn-primary"
            :extensions="extensions"
            :accept="accept"
            :multiple="multiple"
            :directory="directory"
            :create-directory="createDirectory"
            :thread="thread < 1 ? 1 : (thread > 5 ? 5 : thread)"
            :drop="drop"
            :drop-directory="dropDirectory"
            v-model="new_files"
            @input-filter="fileUploadInputFilter"
            @input-file="fileUploadInputFile"

            ref="upload">
            Selecione o documento
          </file-upload>
        </div>
      </div>
      <div class="row">
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Miniatura</th>
                <th>Tipo de Documento</th>
                <th style="text-align: center;">Status</th>
                <th style="text-align: center;">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!perfil_documentos.length">
                <td colspan="4">
                  <div class="text-center p-5">
                    <h4>Não existem documentos cadastrados. <br>Por favor, clique em Adicionar Documentos.</h4>
                  </div>
                </td>
              </tr>
              <tr v-for="(perfilDocumento, index) in perfil_documentos" :key="perfilDocumento.file.id">
                <td @click="downloadDocument(perfilDocumento)">
                  <img class="td-image-thumb" v-if="(perfilDocumento.file.thumb && perfilDocumento.file.contentType != 'application/pdf')" :src="perfilDocumento.file.thumb" />
                  <span v-else-if="perfilDocumento.file.contentType == 'application/pdf'">
                    <img src="@/assets/images/pdf.png" alt="PDF" style="width: 32px;" />
                  </span>
                  <span v-else>--</span>
                </td>
                <td>
                  {{perfilDocumento.tipo_documento.nome}}
                </td>
                <td style="text-align: center;">
                  {{perfilDocumento.status}}
                </td>
                <td style="text-align: center;">
                  <div class="btn-group">
                    <button class="btn btn-warning btn-sm" type="button" @click="removerPerfilDocumento(index)">
                      Remover
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="show_alerta_salvar_documentos" class="col-md-12" style="text-align: center; margin-top: -40px; color: red; font-weight: bold;">
          <span><i>*** CLIQUE EM SALVAR PARA CONCLUIR AS ALTERAÇÕES DOS DOCUMENTOS ***</i></span>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <button
            ref="btnSalvar"
            type="submit"
            slot="footer"
            class="btn btn-sm btn-primary mt-3"
            fill
          >
            Salvar
          </button>
        </div>
      </div>
    </card>

    <card v-if="isGestorOrCurador || pessoa.dados_complementares.approvalStatus == CWLStatusInvestidor.Reprovado">
      <h5 slot="header" class="title mt-3" v-if="isGestor">Gestor</h5>
      <h5 slot="header" class="title mt-3" v-if="isCurador">Curador</h5>
      <h5 slot="header" class="title mt-3" v-if="isUsuario">Curadoria</h5>

      <div class="row" v-if="isGestorOrCurador">
        <div class="col-md-12">
          <label for="quillEditor">Observações</label>
          <quill-editor
            ref="quillEditor"
            v-model="pessoa.dados_complementares.approvalObservacao"
            :options="editorCuradoriaOption"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
            @change="onCuradoriaEditorChange($event)"
            :disabled="
              !isGestorOrCurador && pessoa.dados_complementares.approvalStatus == CWLStatusInvestidor.Reprovado
            "
          />
        </div>
      </div>

      <div class="row" v-if="isUsuario">
        <div class="col-md-12">
          <label
            class="control-label"
            style="cursor: default"
          >
            Seu cadastro foi analisado pela curadoria e não foi aprovado pelos motivos abaixo:
          </label>
        </div>
      </div>

      <div class="row" v-if="isUsuario">
        <div class="col-md-12">
          <div style="font-style: italic; font-size: 12px;" v-html="pessoa.dados_complementares.approvalObservacao"></div>
        </div>
      </div>

      <div class="row" v-if="isGestorOrCurador">
        <div class="col-md-6">
          <button
            type="button"
            slot="footer"
            class="btn btn-sm btn-primary mt-3"
            @click="aprovarCadastro"
            fill
          >
            Aprovar cadastro
          </button>
          <button
            type="button"
            slot="footer"
            class="btn btn-sm mt-3 btn-warning"
            @click="reprovarCadastro"
            fill
          >
            Reprovar cadastro
          </button>
        </div>
      </div>
    </card>
    <vue-topprogress ref="topProgress"></vue-topprogress>
  </form>
</template>

<style>
  .td-image-thumb {
    max-width: 4em;
    max-height: 4em;
  }
  .style-chooser .vs__search::placeholder,
  .style-chooser .vs__dropdown-toggle {
    height: calc(2.25rem + 2px);
    border-color: #c1c1c1;
    border-radius: 0.4285rem;
    line-height: 1.42857;
    color: #555;
  }
  .style-chooser .vs__search::placeholder,
  .style-chooser .vs__dropdown-toggle,
  .style-chooser .vs__dropdown-menu {
    text-transform: lowercase;
    font-variant: small-caps;
  }
  .style-chooser .vs__dropdown-menu {
    margin-top: 3px;
  }
</style>
